export const config = (companyId?: string) => {
  const companyService = process.env.REACT_APP_CUSTOMER_SERVICE;
  const orderService = process.env.REACT_APP_ORDER_SERVICE;
  const productService = process.env.REACT_APP_PRODUCT_SERVICE;
  const configService = process.env.REACT_APP_CONFIG_SERVICE;
  return {
    cognito: {
      poolid: process.env.REACT_APP_COGNITO_POOL_ID,
      clientid: process.env.REACT_APP_ID,
    },
    endpoints: {
      users: configService + 'users',
      companies: companyService + 'api/v2/companies',
      me: companyService + 'api/v2/my-contact',
      contacts: companyService + `api/v2/${companyId}/contacts`,
      orders: orderService + `api/v2/${companyId}/orders`,
      drafts: orderService + `api/v2/${companyId}/drafts`,
      products: productService + `api/v2/${companyId}/products-definitions`,
      locations: companyService + `api/v2/${companyId}/locations`,
    },
    keys: {
      user: 'user',
      current_company: 'current company',
      current_role: 'current role',
      drafts_search_value: 'drafts-search-value',
      ff2_search_value: 'ff2-search-value',
    },
    stopTypingDetection: 1000,
  };
};
export default config;
